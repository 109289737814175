import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateUser } from "services/usersService";
import Layout from "template";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setCurrentUser } from "redux/slices/users";
import { setJWTToken } from "redux/slices/auth";
import { Navigate } from "react-router-dom";
import Giphy from "giphy-js-sdk-core";
import Channels from "./Channels";
import Chat from "./Chat";
import ActiveMembers from "./ActiveMembers";
import './styles.css';

const giphyClient = Giphy("YOUR_GIPHY_API_KEY");

interface Discussion {
    id: string;
    title: string;
    content: string;
    author: string;
    replies: Reply[];
    upvotes: number;
    newReply?: string;
}

interface Reply {
    id: string;
    content: string;
    author: string;
    upvotes: number;
}

interface Gif {
    id: string;
    url: string;
}

const channels = ["general", "random", "support", "games"];

export const TalkHive: React.FC = () => {
    const dispatch = useDispatch();
    const authToken = useSelector((state: RootState) => state.authToken);
    const currentUser = authToken.userData;
    const [user, setUser] = useState({
        id: currentUser?.id || "",
        first_name: currentUser?.first_name || "",
        last_name: currentUser?.last_name || "",
        email: currentUser?.email || "",
        account: currentUser?.account || ""
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [discussions, setDiscussions] = useState<Discussion[]>([]);
    const [newDiscussion, setNewDiscussion] = useState({ title: "", content: "" });
    const [filter, setFilter] = useState<string>("recent");
    const [activeMembers, setActiveMembers] = useState<string[]>(["User1", "User2", "User3"]);
    const [gifs, setGifs] = useState<Gif[]>([]);
    const [selectedGif, setSelectedGif] = useState<Gif | null>(null);
    const [selectedChannel, setSelectedChannel] = useState<string>("general");

    useEffect(() => {
        const now = Date.now();
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');
        const storedUserData = localStorage.getItem('userData');

        if (token && expire && parseInt(expire) > now && storedUserData) {
            const userData = JSON.parse(storedUserData);
            if (!authToken.jwtToken) {
                dispatch(setJWTToken({
                    jwtToken: token,
                    expiryDate: expire,
                    userData: userData,
                    errorMessage: ""
                }));
                dispatch(setCurrentUser(userData));
                setUser(userData);
            }
        } else {
            setIsAuthenticated(false);
        }
    }, [dispatch, authToken.jwtToken]);

    const handleNewDiscussionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewDiscussion((prev) => ({ ...prev, [name]: value }));
    };

    const handleCreateDiscussion = () => {
        const newDiscussionObject: Discussion = {
            id: Date.now().toString(),
            title: newDiscussion.title,
            content: newDiscussion.content,
            author: user.first_name + " " + user.last_name,
            replies: [],
            upvotes: 0,
            newReply: "",
        };
        setDiscussions((prevDiscussions) => [...prevDiscussions, newDiscussionObject]);
        setNewDiscussion({ title: "", content: "" });
    };

    const handleReplyChange = (discussionId: string, value: string) => {
        setDiscussions((prevDiscussions) =>
            prevDiscussions.map((discussion) =>
                discussion.id === discussionId
                    ? { ...discussion, newReply: value }
                    : discussion
            )
        );
    };

    const handleAddReply = (discussionId: string) => {
        setDiscussions((prevDiscussions) =>
            prevDiscussions.map((discussion) =>
                discussion.id === discussionId
                    ? {
                        ...discussion,
                        replies: [
                            ...discussion.replies,
                            {
                                id: Date.now().toString(),
                                content: discussion.newReply || "",
                                author: user.first_name + " " + user.last_name,
                                upvotes: 0,
                            },
                        ],
                        newReply: "",
                    }
                    : discussion
            )
        );
    };

    const handleUpvoteDiscussion = (discussionId: string) => {
        setDiscussions((prevDiscussions) =>
            prevDiscussions.map((discussion) =>
                discussion.id === discussionId
                    ? { ...discussion, upvotes: discussion.upvotes + 1 }
                    : discussion
            )
        );
    };

    const handleUpvoteReply = (discussionId: string, replyId: string) => {
        setDiscussions((prevDiscussions) =>
            prevDiscussions.map((discussion) =>
                discussion.id === discussionId
                    ? {
                        ...discussion,
                        replies: discussion.replies.map((reply) =>
                            reply.id === replyId
                                ? { ...reply, upvotes: reply.upvotes + 1 }
                                : reply
                        ),
                    }
                    : discussion
            )
        );
    };

    const handleFilterChange = (filter: string) => {
        setFilter(filter);
    };

    const fetchGifs = async (query: string) => {
        try {
            const response = await giphyClient.search('gifs', { q: query });
            setGifs(response.data.map((gif: any) => ({ id: gif.id, url: gif.images.fixed_height.url })));
        } catch (error) {
            console.error("Erreur lors de la recherche de GIFs:", error);
        }
    };

    const handleGifSelect = (gif: Gif) => {
        setSelectedGif(gif);
    };

    const handleChannelSelect = (channel: string) => {
        setSelectedChannel(channel);
    };

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <Layout>
            <div className="talk-container">
                <Channels channels={channels} onChannelSelect={handleChannelSelect} selectedChannel={selectedChannel} />
                <Chat
                    selectedChannel={selectedChannel}
                    newDiscussion={newDiscussion}
                    discussions={discussions}
                    gifs={gifs}
                    selectedGif={selectedGif}
                    loading={loading}
                    filter={filter}
                    handleNewDiscussionChange={handleNewDiscussionChange}
                    handleCreateDiscussion={handleCreateDiscussion}
                    fetchGifs={fetchGifs}
                    handleGifSelect={handleGifSelect}
                    handleUpvoteDiscussion={handleUpvoteDiscussion}
                    handleUpvoteReply={handleUpvoteReply}
                    handleReplyChange={handleReplyChange}
                    handleAddReply={handleAddReply}
                />
                <ActiveMembers members={activeMembers} />
            </div>
        </Layout>
    );
};
