import React, { useEffect } from "react";
import { Button, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { MenuItems } from "./MenuItems";

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleActiveMenu = (menuName: string) => {
        dispatch(activateMenu(menuName));
    };
    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
    const isActiveMenu = useSelector((state: RootState) => state.template.activeMenu);

    const handleMenuClick = (path: string) => {
        dispatch(activateMenu(path));
        navigate(path, { replace: true });
    };

    const handleSubMenuClick = (path: string) => {
        navigate(path, { replace: true });
    };

    useEffect(() => {
        document.body.classList.toggle('sidebar-toggled', isSidebar);
    }, [isSidebar]);

    return (
        <ul className={isSidebar ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled d-none d-md-block' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-none d-md-block'}
            id="accordionSidebar">

                <div className="mobile-menu-logo">
                    <img src="/3emelieu_logo_and_txt_Blanc.png" alt="Logo"
                         style={{maxWidth: "100%", padding: '10px 15% 15% 32px'}}/>
                </div>

                <hr className="sidebar-divider d-none d-md-block"/>
                {MenuItems.map((item, i) => {
                    const Icon = item.icon;
                    return item.subMenu && item.subMenu.length > 0 ?
                        <li className="nav-item" key={`Menu-${i}`}>
                            <NavLink className={isActiveMenu === item.title ? '' : 'collapsed'} data-toggle="collapse"
                                     aria-expanded={isActiveMenu === item.title ? true : false}
                                     onClick={() => handleActiveMenu(item.title)}>
                                <Icon size={24} weight="bold"/>
                                <span>{item.title}</span></NavLink>
                            <div id="collapseTwo" className={isActiveMenu === item.title ? "collapse show" : "collapse"}
                                 aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner">
                                    <h6 className="collapse-header">{item.title}:</h6>
                                    {
                                        item.subMenu.map((sub, k) => {
                                            const SubIcon = sub.icon;
                                            return <Button key={`SubMenu-${k}`} variant="link"
                                                           className="collapse-item pt-0"
                                                           onClick={() => handleSubMenuClick(sub.path)}>
                                                <SubIcon size={24} weight="bold"/><span>{sub.title}</span></Button>
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="sidebar-divider"/>
                        </li>
                        :
                        <li className={isActiveMenu === item.path ? "nav-item active" : "nav-item"} key={`Menu-${i}`}>
                            <Button variant="link" className="nav-link pt-0" onClick={() => handleMenuClick(item.path)}>
                                <Icon size={18} weight="bold"/>
                                <span>{item.title}</span></Button>
                            <hr className="sidebar-divider"/>
                        </li>
                })}
        </ul>
);
};

export default Sidebar;
