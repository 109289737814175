import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Users } from 'phosphor-react';
import './donors.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

type Donor = {
    id: number;
    name: string;
    value: number;
    idUsers: number;
    type: string; // Nouveau champ pour le type de don
};

type DonorsListProps = {
    userData: {
        id: number;
    };
};

const DonorsList: React.FC<DonorsListProps> = ({ userData }) => {
    const [donors, setDonors] = useState<Donor[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDonors = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`${API_BASE_URL}/donors?userId=${userData.id}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const donorsData = await response.json();
                console.log('Fetched Donors Data:', donorsData);
                setDonors(donorsData);
            } catch (error) {
                console.error('Error fetching donors:', error.message);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDonors();
    }, [userData.id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="alert alert-danger" role="alert">{error}</div>;
    }

    return (
        <div className="text-white">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title">Liste des contributeurs</h5>
                <div className="d-flex align-items-center">
                    <Users size={20} className="mr-2" />
                    <span><span style={{ color: '#00ff8c' }}>{donors.length}</span> Contributeurs</span>
                </div>
            </div>
            {donors.length === 0 ? (
                <div className="alert alert-warning" role="alert">
                    Aucun contributeur pour le moment.
                </div>
            ) : (
                <Table striped bordered hover variant="dark" className="table">
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Montant (CHF)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {donors.map((donor) => (
                        <tr
                            key={donor.id}
                           style={donor.type === 'scholarship' ? { background: 'linear-gradient(to right, rgba(255, 204, 0, 0.8) 0%, rgba(255,204,0,0) 50%)' } : {}}

                        >
                            <td>{donor.name}</td>
                            <td>{donor.value}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default DonorsList;
