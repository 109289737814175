import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface CurriculumCardProps {
    curriculum: any;
    handleEnroll: () => void;
}

const CurriculumCard: React.FC<CurriculumCardProps> = ({ curriculum, handleEnroll }) => {
    return (
        <Card className="h-100" style={{ borderColor: 'blue' }}>
            <div style={{
                position: 'relative',
                height: 64,
                width: '100%',
                overflow: 'hidden',
                borderRadius: 6
            }}>
                <span style={{position: 'absolute', fontSize: '3em', right: '0px', top: '-10px', zIndex: 100}}> 🎓</span>
                <div style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(https://3emelieu.ch/${curriculum.image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}></div>
                <div style={{
                    height: '100%',
                    width: '100%',
                    background: 'linear-gradient(to bottom, rgba(30, 30, 65,0.2), rgba(30, 30, 65,1.0))',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '6px',
                    borderTop: '1px solid rgba(255,255,255,0.2)',
                }}>
                    <div
                        style={{
                            display: 'inline-block',
                            padding: '0.5em 1em',
                            borderRadius: '20px',
                            color: 'white',
                            fontSize: '1.4em',
                            zIndex: 3,
                            position: 'relative'
                        }}>
                        {curriculum.Type === 'Certificate' ? 'Certificat' :
                            curriculum.Type === 'Diploma' ? 'Diplôme' :
                                curriculum.Type === 'Bachelor' ? 'Bachelor' : curriculum.Type}
                    </div>

                </div>
            </div>
            <Card.Body>
                <Card.Title className="text-dark">
                    <span style={{fontSize: '1.1em',color: curriculum.Type === 'Certificate' ? '#2ce5ff' : // Vert pour Certificat
                            curriculum.Type === 'Diploma' ? '#FFC107' :    // Jaune pour Diplôme
                                curriculum.Type === 'Bachelor' ? '#bb41ff' :   // Bleu pour Bachelor
                                    '#9E9E9E',  // Gris par défaut pour les autres types
                        }}>{curriculum.Title}</span>
                </Card.Title>
                <Card.Text className="text-dark">
                    <strong>Durée:</strong> {curriculum.Duration} heures
                </Card.Text>
                <Card.Text className="text-dark">
                    <strong>Prix:</strong> {curriculum.Price} CHF
                </Card.Text>
                <Button variant="primary" className="btn-primary-3emelieu" onClick={handleEnroll}>
                    Informations
                </Button>
            </Card.Body>
        </Card>
    );
};

export default CurriculumCard;
