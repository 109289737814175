import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

const SuccessPage: React.FC = () => {
    const [transactionData, setTransactionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactionData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const sessionId = urlParams.get('session_id');
            console.log('Session ID from URL:', sessionId);

            if (!sessionId) {
                setError('No session ID provided');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`${API_BASE_URL}/get-transaction?session_id=${sessionId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Transaction data fetched:', data);
                setTransactionData(data);
            } catch (error) {
                console.error('Fetching transaction data failed:', error);
                setError(error.message);
            }
            setLoading(false);
        };

        fetchTransactionData();
    }, []);

    if (loading) {
        return (
            <Container className="mt-5 text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-5 text-center">
                <Card className="text-center">
                    <Card.Body>
                        <Card.Title className="text-danger">Erreur</Card.Title>
                        <Card.Text>{error}</Card.Text>
                        <a href="/" className="btn btn-primary">Retour</a>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title className="text-success">Merci pour votre contribution !</Card.Title>
                            <Card.Text>
                                Votre paiement a été effectué avec succès. Nous vous remercions pour votre soutien.
                            </Card.Text>
                            {transactionData && (
                                <div>
                                    <p>Transaction ID: {transactionData.id}</p>
                                    <p>Amount: {transactionData.amount_total / 100} CHF</p>
                                    <p>Donor: {transactionData.metadata.donorName}</p>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SuccessPage;
