import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import Layout from "../../template";
import CrowdfundingSection from "./CrowdfundingSection";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { setJWTToken } from "../../redux/slices/auth";
import { setCurrentUser } from "../../redux/slices/users";
import { io } from "socket.io-client";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: RootState) => state.authToken.userData);
    const [user, setUser] = useState(currentUser);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [showModal, setShowModal] = useState(false);

    // Function to handle modal close and update the modal seen status
    const handleCloseModal = useCallback(async () => {
        setShowModal(false);
        try {
            await fetch(`${API_BASE_URL}/users/${user.id}/modal-seen`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ seen: true }),  // Send that the modal has been seen
            });
        } catch (error) {
            console.error('Error updating modal seen status:', error);
        }
    }, [user.id]);

    // Function to check if the modal has already been seen by the user
    const checkIfModalSeen = async (userId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/users/${userId}/modal-seen`);
            const result = await response.json();
            return result.seen; // Return true/false based on the response
        } catch (error) {
            console.error('Error checking if modal was seen:', error);
            return false;
        }
    };

    useEffect(() => {
        // Check if the modal has been seen and update the modal state
        const checkModalStatus = async () => {
            const modalSeen = await checkIfModalSeen(user.id);
            if (!modalSeen) {
                setShowModal(true); // Show the modal if it hasn't been seen
            }
        };
        if (user.id) {
            checkModalStatus();
        }
    }, [user.id]);

    // Socket setup for user updates
    useEffect(() => {
        const socket = io(API_BASE_URL, {
            transports: ['websocket'],
        });

        socket.on("connect", () => {
            console.log("Connected to socket server:", socket.id);

            socket.on("userUpdate", (updatedUser) => {
                setUser(updatedUser);
                localStorage.setItem('userData', JSON.stringify(updatedUser));
                dispatch(setCurrentUser(updatedUser));
            });
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from socket server");
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch]);

    // Authentication check
    useEffect(() => {
        const now = Date.now();
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');

        if (!token || !expire || parseInt(expire) <= now) {
            setIsAuthenticated(false);
            setLoading(false);
            return;
        }

        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            setUser(userData);
            dispatch(setJWTToken({
                jwtToken: token,
                expiryDate: expire,
                userData: userData,
                errorMessage: ""
            }));
            dispatch(setCurrentUser(userData));
        } else {
            setIsAuthenticated(false);
        }
        setLoading(false);
    }, [dispatch]);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Bienvenue sur le système de financement participatif</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Ce système vous permet de lever des fonds pour financer vos études en obtenant du soutien de la communauté.</p>
                    <p>Utilisez cette fonctionnalité pour partager votre lien de financement participatif avec vos proches et collecter des fonds.</p>

                    {/* Embed YouTube Video */}
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            className="embed-responsive-item"
                            src="https://www.youtube.com/embed/4y6Ga4_8F04"
                            allowFullScreen
                            title="Introduction 3èmelieu Cloud"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Layout>
                <Container fluid className="px-3 mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                    </div>
                    <Row className="mt-3">
                        <Col xs={12}>
                            <CrowdfundingSection
                                userData={user}
                                crowdfundingLink={`https://cloud.3emelieu.ch/user/crowdfunding/${user.id}`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    );
};

export default Dashboard;
