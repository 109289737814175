import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { ArrowFatUp, ArrowFatDown } from "phosphor-react";

type PostProps = {
    id: number;
    title: string;
    content: string;
    votes: number;
    onVote: (id: number, change: number) => void;
};

const Post: React.FC<PostProps> = ({ id, title, content, votes, onVote }) => {
    const voteColor = votes > 0 ? "text-success" : votes < 0 ? "text-danger" : "text-muted";

    return (
        <Card className="shadow-sm rounded border-0 h-100">
            <Card.Body className="p-3 d-flex flex-column">
                <Card.Title className="mb-1 h5">{title}</Card.Title>
                <Card.Text className="mb-2 text-muted small">{content}</Card.Text>
                <Row className="mt-auto align-items-center">
                    <Col xs="auto">
                        <Button variant="link" className="p-0" onClick={() => onVote(id, 1)}>
                            <ArrowFatUp size={20} weight="bold" />
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="link" className="p-0" onClick={() => onVote(id, -1)}>
                            <ArrowFatDown size={20} weight="bold" />
                        </Button>
                    </Col>
                    <Col className="text-center">
                        <div className={`font-weight-bold small ${voteColor}`}>{votes} votes</div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Post;
