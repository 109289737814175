import React, {useState, useEffect, useCallback, useMemo} from "react";
import {Alert, Button, Row, Col, Form, Card, ProgressBar} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {setCoursesList, setCoursesMessage} from "../../redux/actions";
import {RootState} from "../../redux/reducers";
import {getCourses} from "../../services/coursesService";
import Layout from "../../template";
import {Constant} from "../../template/Constant";
import CourseCard from "./CoursesCard";
import CurriculumCard from "./CurriculumCard";
import {Funnel, MagnifyingGlass, ArrowLeft, ArrowRight} from "phosphor-react";
import CourseDetailModal from "./CourseDetailModal";
import {setJWTToken} from "../../redux/slices/auth";
import {setCurrentUser} from "../../redux/slices/users";

const Cours: React.FC = () => {
    const dispatch = useDispatch();
    const coursesData = useSelector((state: RootState) => state.courses);
    const currentUser = useSelector((state: RootState) => state.users.currentUser);

    const [user, setUser] = useState<any>(null);
    const [highlightedCourseData, setHighlightedCourseData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState(1);
    const [pageSize] = useState(16);
    const [showFilters, setShowFilters] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState<any>(null);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const [filterType, setFilterType] = useState<string>('all');
    const [filterPrice, setFilterPrice] = useState<number | undefined>(undefined);
    const [filterDuration, setFilterDuration] = useState<number | undefined>(undefined);
    const [filterStatus, setFilterStatus] = useState<string>('');

    const loadUserData = useCallback(() => {
        const now = Date.now();
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');

        if (!token || !expire || parseInt(expire) <= now) {
            setIsAuthenticated(false);
            setLoading(false);
            return;
        }

        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            setUser(userData);
            dispatch(setJWTToken({
                jwtToken: token,
                expiryDate: expire,
                userData: userData,
                errorMessage: ""
            }));
            dispatch(setCurrentUser(userData));
            setHighlightedCourseData(userData);
        } else {
            setIsAuthenticated(false);
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        loadUserData();
    }, [loadUserData]);

    const getData = useCallback(async () => {
        try {
            const response = await getCourses({
                page,
                pageSize,
                searchKey,
                filters: {price: filterPrice, duration: filterDuration, status: filterStatus, type: filterType}
            });

            if (response?.records) {
                const totalCount = response.totalCount || response.records.length;
                dispatch(setCoursesList({
                    pageNo: page,
                    pageSize: pageSize,
                    list: response.records,
                    totalCount,
                    searchKey
                }));
            } else {
                dispatch(setCoursesMessage("No Record Found"));
            }
        } catch (error) {
            dispatch(setCoursesMessage("Erreur lors de la récupération des données des cours. Veuillez réessayer plus tard."));
        }
    }, [dispatch, page, pageSize, searchKey, filterPrice, filterDuration, filterStatus, filterType]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        setPage(1);
    }, [searchKey]);

    useEffect(() => {
        if (highlightedCourseData && coursesData.list) {
            const crowdfundingCourse = coursesData.list.find(course => course.Title === highlightedCourseData?.cursus);
            if (crowdfundingCourse) {
                const percentageFunded = calculateProgress(
                    parseFloat(highlightedCourseData.reserved_funds),
                    parseFloat(highlightedCourseData.targetAmount)
                );
                if (percentageFunded !== highlightedCourseData.percentageFunded) {
                    setHighlightedCourseData({
                        ...highlightedCourseData,
                        percentageFunded,
                        crowdfundingCourse
                    });
                }
            }
        }
    }, [coursesData.list, highlightedCourseData]);

    useEffect(() => {
        // React to changes in the current user in the Redux state
        if (currentUser && coursesData.list) {
            const updatedCourse = coursesData.list.find(course => course.Title === currentUser.cursus);
            if (updatedCourse) {
                setHighlightedCourseData({
                    ...currentUser,
                    crowdfundingCourse: updatedCourse,
                });
            }
        }
    }, [currentUser, coursesData.list]);

    const handleApplyFilters = () => {
        setFilterPrice(price);
        setFilterDuration(duration);
        setFilterStatus('');
        setPage(1);
        getData();
    };

    const handleResetFilters = () => {
        setPrice(undefined);
        setDuration(undefined);
        setFilterPrice(undefined);
        setFilterDuration(undefined);
        setFilterStatus('');
        setSearchKey('');
        setPage(1);
        getData();
    };

    const totalPages = useMemo(() => Math.ceil(coursesData.totalCount / pageSize), [coursesData.totalCount, pageSize]);

    const handleShowModal = (course: any) => {
        setSelectedCourse(course);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCourse(null);
    };

    const calculateProgress = (reservedFunds: number, targetAmount: number) => {
        return ((reservedFunds / targetAmount) * 100).toFixed(2);
    };


    const renderCrowdfundingSection = () => {
        if (highlightedCourseData?.crowdfundingCourse) {
            return (
                <Row className="justify-content-center mb-4" >
                    <Col md={8}>
                        <Card className="border-warning rounded mb-4">
                            <Card.Body style={{border: '2px solid #00ff8c'}}>

                                <div className="text-center">
                                    <h4 style={{marginTop: 10}}>Crowdfunding en cours</h4>
                                    <div style={{margin: 10, padding: 10, border: '2px solid rgba(255,255,255,0.1', borderRadius: 16}}>
                                        <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
                                            {highlightedCourseData.crowdfundingCourse.Type && (
                                                <div style={{
                                                    marginRight: 14,
                                                    // Border color based on the course type
                                                    border: `2px solid ${
                                                        highlightedCourseData.crowdfundingCourse.Type === 'Certificate' ? '#2ce5ff' :
                                                            highlightedCourseData.crowdfundingCourse.Type === 'Diploma' ? '#FFC107' :
                                                                highlightedCourseData.crowdfundingCourse.Type === 'Bachelor' ? '#ad21f3' : 'transparent'
                                                    }`,
                                                    // Background color with transparency based on the course type
                                                    backgroundColor: `${
                                                        highlightedCourseData.crowdfundingCourse.Type === 'Certificate' ? 'rgba(44,229,255,0.5)' :
                                                            highlightedCourseData.crowdfundingCourse.Type === 'Diploma' ? 'rgba(255,193,7,0.5)' :
                                                                highlightedCourseData.crowdfundingCourse.Type === 'Bachelor' ? 'rgba(173,33,243,0.5)' : 'transparent'
                                                    }`,
                                                    padding: 4,
                                                    borderRadius: 6
                                                }}>
                                                    {/* Display the course type label */}
                                                    {highlightedCourseData.crowdfundingCourse.Type === 'Certificate' ? 'Certificat' :
                                                        highlightedCourseData.crowdfundingCourse.Type === 'Diploma' ? 'Diplôme' :
                                                            highlightedCourseData.crowdfundingCourse.Type === 'Bachelor' ? 'Bachelor' :
                                                                highlightedCourseData.crowdfundingCourse.Type}
                                                </div>
                                            )}


                                            <h1>{highlightedCourseData.crowdfundingCourse.Title || "Titre non disponible"}</h1>

                                        </div>
                                    </div>
                                    <a href="/dashboard">
                                        <div className="btn btn-primary" style={{marginTop: 20}}>Dashboard</div>
                                    </a>
                                    {/*
                                    <ProgressBar
                                        now={parseFloat(coursesData.percentageFunded || "0")}
                                        label={`${Math.floor(parseFloat(highlightedCourseData.percentageFunded || "0"))}%`}
                                        className="bg-gradient-success my-3"
                                    />
                                    <h3>
                                        {parseFloat(highlightedCourseData.reserved_funds || "0").toFixed(2)} CHF
                                        collectés sur {highlightedCourseData.targetAmount || "0"} CHF
                                    </h3>
                                    <hr/>
                                    <h4>{coursesData.daysLeft || "0"} jours restants</h4>
                                    */}
                                    {/* <h4>{coursesData.backers || "0"} contributeurs</h4> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            );
        }
        return null;
    };

    const generateUniqueKey = (course: any, index: number) => {
        return `${course.id}-${index}-${new Date().getTime()}`;
    };

    const filteredCourses = useMemo(() =>
        coursesData.list.filter(course =>
            (filterType === 'all' || course.RecordType === filterType) &&
            (filterPrice === undefined || (course.Price !== undefined && course.Price <= filterPrice)) &&
            (filterDuration === undefined || (course.Duration !== undefined && course.Duration <= filterDuration)) &&
            (filterStatus === '' || course.Status === filterStatus)
        ), [coursesData.list, filterType, filterPrice, filterDuration, filterStatus]);

    return (
        <Layout>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Cours</h1>
                </div>
                <div className="d-flex flex-column min-vh-100">
                    {coursesData.message && (
                        <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade show">
                            {coursesData.message}
                            <Button type="button" className="close" aria-label="Close"
                                    onClick={() => dispatch(setCoursesMessage(''))}>
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </Alert>
                    )}

                    {renderCrowdfundingSection()}

                    <div className="filters-zone mb-4">
                        <Form style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'nowrap'
                        }}>
                            <Form.Control type="text" value={searchKey} onChange={(e) => setSearchKey(e.target.value)}
                                          placeholder="Rechercher des cours" className="form-control-sm"/>
                            {/*
                            <Button variant="outline-primary" onClick={getData} className="ml-2" style={{marginLeft: '4px'}}>
                                <MagnifyingGlass size={20} />
                            </Button>
                            */}
                        </Form>
                        <Button
                            variant="outline-primary"
                            onClick={() => setFilterType(prev => prev === 'all' ? 'curriculum' : 'all')}
                        >
                            {filterType === 'all' ? 'Afficher les formations Pro 🎓' : 'Afficher Tout'}
                        </Button>
                        <Button variant="outline-primary" onClick={() => setShowFilters(prev => !prev)}>
                            <Funnel size={20}/> Filtres
                        </Button>
                    </div>

                    {showFilters && (
                        <div className="filters-zone">
                            <Row className="mb-4">
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Prix (CHF)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={price || ''}
                                            onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                                            placeholder="Entrer le prix maximum"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Durée (heures)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={duration || ''}
                                            onChange={(e) => setDuration(e.target.value ? Number(e.target.value) : undefined)}
                                            placeholder="Entrer la durée maximum"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>Filtres</Form.Label>
                                        <div>
                                            <Button variant="outline-primary" onClick={handleApplyFilters}
                                                    style={{fontSize: '1.2em', marginRight: 10}}>
                                                Appliquer
                                            </Button>
                                            <Button variant="outline-primary" onClick={handleResetFilters}
                                                    style={{fontSize: '1.2em'}}>
                                                Réinitialiser
                                            </Button>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    )}

                    <Row>
                        {filteredCourses.map((course, index) => (
                            <Col md={3} key={generateUniqueKey(course, index)} className="mb-4">
                                {course.RecordType === 'course' ? (
                                    <CourseCard course={course} handleEnroll={() => handleShowModal(course)}/>
                                ) : (
                                    <CurriculumCard curriculum={course} handleEnroll={() => handleShowModal(course)}/>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <div className="pagination d-flex justify-content-center mt-4">
                        <Button variant="outline-primary" disabled={page === 1} onClick={() => setPage(page - 1)}>
                            <ArrowLeft size={20}/>
                        </Button>
                        <span className="mx-3">{`Page ${page} de ${totalPages}`}</span>
                        <Button variant="outline-primary" disabled={page === totalPages}
                                onClick={() => setPage(page + 1)}>
                            <ArrowRight size={20}/>
                        </Button>
                    </div>
                </div>
            </div>
            {selectedCourse && (
                <CourseDetailModal show={showModal} handleClose={handleCloseModal} courseData={selectedCourse}/>
            )}
        </Layout>
    );
};

export default Cours;
