// src/components/auth/index.tsx

import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { setJWTToken } from '../../redux/slices/auth';
import { setCurrentUser } from '../../redux/slices/users';

const AuthenticatedRoute: FC<{ element: JSX.Element }> = ({ element }) => {
    const dispatch = useDispatch();
    const authToken = useSelector((state: RootState) => state.authToken);
    const [loading, setLoading] = useState(true); // État de chargement ajouté
    const now = Date.now();

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');
        const storedUserData = localStorage.getItem('userData');

        //console.log("Checking local storage for token and expiry");
        //console.log("Token:", token);
        //console.log("Expire:", expire);
        //console.log("Stored User Data:", storedUserData);

        if (token && expire && parseInt(expire) > now && storedUserData) {
            const userData = JSON.parse(storedUserData);
            //console.log("Token, expiry and user data are valid");
            if (!authToken.jwtToken) {
                //console.log("No token in Redux, setting token and user data in Redux");
                dispatch(setJWTToken({
                    jwtToken: token,
                    expiryDate: expire,
                    userData: userData,
                    errorMessage: ""
                }));
                dispatch(setCurrentUser(userData));
            }
        } else {
            console.log("Token or expiry invalid, or no user data found");
        }
        setLoading(false); // Mise à jour de l'état de chargement
    }, [dispatch, now, authToken.jwtToken]);

    //console.log("Current auth token from Redux:", authToken.jwtToken);
    //console.log("Current auth token expiry from Redux:", authToken.expiryDate);

    if (loading) {
        return <div>Loading...</div>; // Affichage d'un écran de chargement temporaire
    }

    if (authToken.jwtToken && parseInt(authToken.expiryDate) > now) {
        //console.log("Auth token is valid, rendering element");
        return element;
    } else {
        //console.log("Auth token is invalid or expired, redirecting to login");
        return <Navigate to="/" replace />;
    }
};

export default AuthenticatedRoute;
