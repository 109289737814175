const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

export const getUser = async (id: string) => {
    console.log(`Sending GET request to fetch user with ID: ${id}`);
    const response = await fetch(`${API_BASE_URL}/users/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    if (!response.ok) {
        console.error(`Failed to fetch user with ID: ${id}`, response.statusText);
        throw new Error('Failed to fetch user');
    }

    const data = await response.json();
    console.log("Fetched user data:", data);
    return data;
};

export const updateUser = async (userData: any, userId: string) => {
    try {
        console.log(`Sending PUT request to ${API_BASE_URL}/${userId} with data`, userData);
        const response = await fetch(`${API_BASE_URL}/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` // Assurez-vous que le token est correct
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            console.log(`Failed to update user with ID: ${userId}, status: ${response.status}`);
            throw new Error(`Failed to update user with ID: ${userId}`);
        }

        const updatedUser = await response.json();
        console.log('User updated successfully:', updatedUser);
        return updatedUser;
    } catch (error) {
        console.error('Error updating user:', error);
        throw new Error('Failed to update user');
    }
};
