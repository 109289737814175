// src/redux/reducers.ts

import { combineReducers } from "redux";

import template from "./slices/template";
import authToken from "./slices/auth";
import users from "./slices/users";
import courses from "./slices/courses"; // Import the courses reducer

const rootReducer = combineReducers({
    template,
    authToken,
    users,
    courses
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
