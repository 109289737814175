import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITemplateProperty {
  sidebar: boolean;
  activeMenu: string;
  topActiveMenu: string;
}

const initialState: ITemplateProperty = {
  sidebar: false,
  activeMenu: '/dashboard',
  topActiveMenu: ''
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    sidebarToggle: (state) => {
      state.sidebar = !state.sidebar;
    },
    activateMenu: (state, action: PayloadAction<string>) => {
      state.activeMenu = action.payload;
    },
    activateTopMenu: (state, action: PayloadAction<string>) => {
      state.topActiveMenu = action.payload;
    }
  },
});

export const { sidebarToggle, activateMenu, activateTopMenu } = templateSlice.actions;

export default templateSlice.reducer;
