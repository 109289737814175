import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface CourseCardProps {
    course: any;
    handleEnroll: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, handleEnroll }) => {
    return (
        <Card className="h-100" style={{ borderColor: 'blue' }}>
            <div style={{height: 64, width: '100%', backgroundImage: `url(https://3emelieu.ch${course.image})`, backgroundPosition: 'center', borderRadius: 6}}><div style={{
                height: '64px',
                width: '100%',
                background: 'linear-gradient(to bottom, rgba(30, 30, 65,0.2), rgba(30, 30, 65,1.0))',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px'
            }}></div></div>
            <Card.Body>
                <Card.Title className="text-dark">
                    <span style={{ fontSize: '1.1em'}}>{course.Title}</span>
                </Card.Title>
                <Card.Text className="text-dark">
                    <strong>Durée:</strong> {course.Duration} heures
                </Card.Text>
                <Card.Text className="text-dark">
                    <strong>Prix:</strong> {course.Price} CHF
                </Card.Text>
                <Button variant="primary" className="btn-primary-3emelieu" onClick={handleEnroll}>
                    Informations
                </Button>
            </Card.Body>
        </Card>
    );
};

export default CourseCard;
