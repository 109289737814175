import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { MenuItems } from "./MenuItems";
import "./MobileMenu.css"; // Import the mobile menu CSS

const MobileMenu: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
    const isActiveMenu = useSelector((state: RootState) => state.template.activeMenu);
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;

    const handleMenuClick = (path: string) => {
        dispatch(activateMenu(path));
        navigate(path, { replace: true });
        dispatch(sidebarToggle()); // Close the menu after selecting an item
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/', { replace: true });
        dispatch(sidebarToggle());
    };

    return (
        <div className={`mobile-menu ${isSidebar ? "active" : ""}`}>
            <div className="mobile-menu-logo">
                <img src="/3emelieu_logo_and_txt_Blanc.png" alt="Logo" style={{maxWidth: "100%", padding: '0px 10% 10% 32px'}}/>
            </div>
            <ul className="mobile-menu-items">
                {MenuItems.map((item, i) => {
                    const Icon = item.icon;
                    return (
                        <li className="mobile-menu-item" key={`Menu-${i}`}>
                            <Button
                                variant="link"
                                className={`nav-link pt-0 ${isActiveMenu === item.path ? "active" : ""}`}
                                onClick={() => handleMenuClick(item.path)}
                            >
                                <Icon size={24} weight="bold"/>
                                <span>{item.title}</span>
                            </Button>
                        </li>
                    );
                })}
                {/* User Profile Section */}
                <li className="mobile-menu-item">
                    <div className="user-profile-section">
                        <div className="user-info">
                            <img className="img-profile rounded-circle" src={userProfile?.profile_picture || "void.png"}
                                 alt="User profile"/>
                            <span
                                className="user-name">{userProfile ? `${userProfile.first_name} ${userProfile.last_name}` : 'NA'}</span>
                        </div>
                        <Button variant="link" className="nav-link pt-0" onClick={() => handleMenuClick('/user')}>
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i> Profile
                        </Button>
                        <Button variant="link" className="nav-link pt-0" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Déconnexion
                        </Button>
                    </div>
                </li>
            </ul>
            <div className="mobile-menu-close">
                <Button variant="warning" onClick={() => dispatch(sidebarToggle())}>
                    Fermer le menu
                </Button>
            </div>
        </div>
    );
};

export default MobileMenu;
