import React from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
    background-color: rgba(23, 27, 77, 0.8); /* Dark background with transparency */
    color: #00ff8c; /* Green text color */
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border: 2px solid #5e3dc0;
`;

const CustomTooltip: React.FC<{ active?: boolean; payload?: any[] }> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { date, balance } = payload[0].payload;
        return (
            <TooltipContainer>
                <div>Balance : <strong style={{fontSize: '1.2em'}}>{balance}</strong></div>
                <span style={{color: '#fff'}}>{date}</span>
            </TooltipContainer>
        );
    }

    return null;
};

export default CustomTooltip;
