import React, {useState, useEffect} from 'react';
import {Modal, Button, Alert} from 'react-bootstrap';

// Phosphor Icons
import {
    CheckCircle,
    Lightbulb,
    Question,
    Calendar,
    Cube,
    Briefcase,
    Laptop,
    Wrench,
    Info,
    ListChecks,
    Target
} from "phosphor-react";

// Lottie Animation
import Lottie from 'lottie-react';
import successAnimation from './Animation-1724405467660.json'; // Ensure the path is correct

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setHighlightedCourseData} from '../../redux/actions';
import {setCurrentUser} from 'redux/slices/users'; // Ensure the path is correct
import {RootState} from '../../redux/reducers';

// Day.js
import dayjs from "dayjs";
import 'dayjs/locale/fr'; // French locale for Day.js
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Custom Styles
import './CourseDetailModal.css';

// Initialize Day.js plugins
dayjs.extend(customParseFormat);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';


const CourseDetailModal = ({show, handleClose, courseData}) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state: RootState) => state.authToken.userData);
    const [error, setError] = useState<string | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isCrowdfundingActive, setIsCrowdfundingActive] = useState(false);
    const [remainingDays, setRemainingDays] = useState(0);
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser.cursus) {
            setIsCrowdfundingActive(true);
            setRemainingDays(currentUser.daysLeft || 0);
        } else {
            setIsCrowdfundingActive(false);
        }
    }, [courseData.Slug, currentUser]);

    const handleActivateCrowdfunding = async () => {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            console.error("No JWT token found in localStorage");
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/${currentUser.id}/cursus`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({cursus: courseData.Title, targetAmount: courseData.Price}),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorData.message}`);
            }

            const data = await response.json();
            localStorage.setItem('highlightedCourseData', JSON.stringify(data));
            dispatch(setHighlightedCourseData(data));

            // Mettre à jour les informations de l'utilisateur
            let updatedUserData = {...currentUser, targetAmount: courseData.Price, cursus: courseData.Title};
            localStorage.setItem('userData', JSON.stringify(updatedUserData));

            // Mettre à jour le state Redux avec les nouvelles informations
            dispatch(setCurrentUser(updatedUserData));

            console.log("userData updated in localStorage and Redux:", updatedUserData);

            // Afficher l'animation de succès
            setShowSuccessAnimation(true);

            // Fermer l'animation après 3 secondes et fermer le modal principal
            setTimeout(() => {
                setShowSuccessAnimation(false);
                handleClose(); // Fermer le modal après l'animation
            }, 3000);
        } catch (err) {
            console.error("Error activating crowdfunding:", err);
            setError(err.message);
        }
    };

    const handleConfirmCrowdfunding = () => {
        setShowConfirmation(true);
    };

    const handleConfirmClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmActivate = () => {
        handleActivateCrowdfunding();
        setShowConfirmation(false);

        // Scroll to the top of the page
        window.scrollTo({
            top: 0,        // Scroll to the top
            behavior: 'smooth'  // Scroll with a smooth animation
        });
    };


    const parseJSONSafely = (jsonString: string | null, fallbackValue: any = []) => {
        try {
            return jsonString ? JSON.parse(jsonString) : fallbackValue;
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return fallbackValue;
        }
    };

    const axesArray = parseJSONSafely(courseData.Axes);
    const agendaArray = parseJSONSafely(courseData.Agenda);
    const subjectsArray = parseJSONSafely(courseData.Subjects);
    const objectivesArray = parseJSONSafely(courseData.Objective, {obj: []}).obj || [];
    const careerArray = parseJSONSafely(courseData.Career, {obj: []}).obj || [];
    const matosData = parseJSONSafely(courseData.Matos);

    const axesElements = axesArray.map((value: string, index: number) => (
        <span key={`axe-${index}`} className={`badge rounded-pill m-1 ${value === 'se' ? 'bg-danger' : 'bg-info'}`}>
            {value === 'se' ? 'Sensei' : 'dS happiness'}
        </span>
    ));

    const getNextCourseDate = (agenda) => {
        const today = dayjs();  // Current date

        // Check if agenda is an object or a string
        const parsedAgenda = typeof agenda === 'string' ? JSON.parse(agenda) : agenda;

        // Parse the StartDate and log the results
        const sessions = parsedAgenda.map(course => {
            const parsedDate = dayjs(course.StartDate, 'DD.MM.YYYY'); // Adjust this format if needed
            console.log('Parsed StartDate:', parsedDate.format('DD MMM YYYY'), 'Original:', course.StartDate);
            return {
                ...course,
                StartDate: parsedDate
            };
        });

        // Find the next session and log comparisons
        const nextSession = sessions.find(course => {
            const isAfterToday = course.StartDate.isAfter(today);
            console.log(`Course: ${course.courseName}, StartDate: ${course.StartDate.format('DD MMM YYYY')}, Is After Today: ${isAfterToday}`);
            return isAfterToday;
        });

        // Return the full course object, not just the date
        return nextSession ? nextSession : null;
    };


    const firstCourse = getNextCourseDate(agendaArray); // Récupérer le premier élément du tableau
    console.log('agendaArray', agendaArray)
    console.log('firstCourse', firstCourse)

// Vérifiez que l'élément existe avant de l'afficher
    const agendaElement = firstCourse ? (
        <div key={`agenda-0`} className="course mb-3">
            <div className="accordion-header-small accordion-button" style={{backgroundColor: 'rgba(82,87,255,0.44)'}}>
                <h4>
                    <span style={{marginRight: 10}}><CheckCircle size={24}/></span> {firstCourse.courseName}
                </h4>
                <div>Date: <strong>{firstCourse.StartDate.format('DD MMM YYYY')}</strong></div>
                <div className="accordion-progress">
                <span style={{color: '#fff', fontSize: '0.9em'}}>
                    {firstCourse.Confirmed < 100 ? (
                        <span style={{whiteSpace: 'nowrap', float: 'right'}}>Cours garanti à :</span>
                    ) : (
                        firstCourse.Confirmed === 100 ? (
                            <span style={{
                                whiteSpace: 'nowrap',
                                color: '#45c4a0',
                                float: 'right',
                            }}>Cours garanti !</span>
                        ) : (
                            <span style={{whiteSpace: 'nowrap', float: 'right'}}>Cours complet</span>
                        )
                    )}
                </span>

                    {/* Optionally display session hours */}
                    {/* <p className="session-hours">Heure: <strong>{firstCourse.session.hours.join(', ')}</strong></p> */}

                    <div className="progress-container mt-2" style={{marginLeft: 6}}>
                        <div className="progress-bar" style={{width: `${firstCourse.Confirmed}%`}}>
                            <div style={{
                                fontSize: '0.6em',
                                color: '#fff',
                                textAlign: 'center',
                                padding: 8,
                                margin: 4,
                                minWidth: 32
                            }}>{firstCourse.Confirmed}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;  // If no future course is found, return null


    const toolsElements = courseData.Tools && courseData.Tools.trim() !== "" ? (
        parseJSONSafely(courseData.Tools).obj.map((tool: any, index: number) => (
            <div key={`tool-${index}`} className="tool-item">
                <img src={tool.img} alt={tool.description} className="tool-image"/>
                <h5 className="tool-description">{tool.description}</h5>
            </div>
        ))
    ) : null;

    const subjectsElements = subjectsArray.map((subject: any, index: number) => (
        <React.Fragment key={`subject-${index}`}>
            <div
                className={`subject accordion-button ${index === 0 ? '' : 'collapsed'}`}
                data-bs-toggle="collapse"
                data-bs-target={`#collapseSubject${index}`}
                aria-expanded={index === 0 ? 'true' : 'false'}
                aria-controls={`collapseSubject${index}`}
            >
                <h4 className="accordion-header-small" id={`subjectHeading${index}`}>
                    <span style={{marginRight: 10, marginTop: '-16px'}}><CheckCircle size={32}/></span>
                    {subject.titre}
                </h4>
            </div>
            <div
                id={`collapseSubject${index}`}
                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                aria-labelledby={`subjectHeading${index}`}
            >
                <div className="list-group-item">
                    <table className="table table-striped table-sm">
                        <tbody style={{border: 'none'}}>
                        {subject.description.map((item: string, itemIndex: number) => (
                            <tr key={`subject-description-${index}-${itemIndex}`}>
                                <td style={{verticalAlign: 'center', color: '#fff', fontWeight: 100}}
                                    dangerouslySetInnerHTML={{__html: item}}></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    ));

    const objectiveElements = objectivesArray.map((objective: any, index: number) => (
        <li className="list-group-item d-flex align-items-center" key={`objective-${index}`}>
            {objective.description}
        </li>
    ));

    const admissionRequirements = (
        <>
            <p><CheckCircle size={32} weight="thin" style={{color: '#00ff8c', marginRight: 10}}/>Être majeur</p>
            <p><CheckCircle size={32} weight="thin" style={{color: '#00ff8c', marginRight: 10}}/>Être capable de
                discernement</p>
            <p><CheckCircle size={32} weight="thin" style={{color: '#00ff8c', marginRight: 10}}/>Maturité gymnasiale,
                diplôme de l’ECG, CFC, ou un titre ou compétences jugés équivalents lors de l’entretien personnel.</p>
        </>
    );

    const toolsAdmit = (
        <div>
            {courseData.Type === 'Certificate' && (
                <div className="mb-4"
                     style={{border: '2px solid rgba(255,255,255,0.2)', padding: 10, borderRadius: 16}}>
                    <h5><Info size={32} weight="thin"
                              style={{color: '#5257ff', marginRight: 10}}/> Admission:
                    </h5>
                    {admissionRequirements}
                </div>
            )}
            {courseData.Type === 'Diploma' && (
                <div className="mb-4"
                     style={{border: '2px solid rgba(255,255,255,0.2)', padding: 10, borderRadius: 16}}>
                    <h5><Info size={32} weight="thin"
                              style={{color: '#5257ff', marginRight: 10}}/> Admission:
                    </h5>
                    {admissionRequirements}
                    <p><CheckCircle size={32} weight="thin" style={{color: '#00ff8c', marginRight: 10}}/><span
                        className="underline-2 style-3 fuchsia">Prérequis pour entrée en 3ème année :</span> avoir
                        effectué
                        la première et la deuxième année dans la branche étudiée, et obtenu avec succès
                        le <strong>Certificat</strong> de la branche ou équivalence selon l’entretien personnel avec la
                        direction.</p>
                </div>
            )}
            {courseData.Type === 'Bachelor' && (
                <div className="mb-4"
                     style={{border: '2px solid rgba(255,255,255,0.2)', padding: 10, borderRadius: 16}}>
                    <h5><Info size={32} weight="thin"
                              style={{color: '#5257ff', marginRight: 10}}/> Admission:
                    </h5>
                    {admissionRequirements}
                    <p><CheckCircle size={32} weight="thin" style={{color: '#00ff8c', marginRight: 10}}/><span
                        className="underline-2 style-3 fuchsia">Prérequis pour entrée en 3ème année :</span> avoir
                        effectué
                        la première et la deuxième année dans la branche étudiée, et obtenu avec succès
                        le <strong>Diplôme</strong> de la branche ou équivalence selon l’entretien personnel avec la
                        direction.</p>
                </div>
            )}
        </div>
    );

    const careerElements = careerArray.map((career: any, index: number) => (
        <li className="list-group-item d-flex align-items-center" key={`career-${index}`}>
            {career.description}
        </li>
    ));

    return (
        <Modal show={show} onHide={handleClose} size="xl" dialogClassName="modal-fullscreen" backdrop="static">
            {showSuccessAnimation ? (
                <div className="d-flex justify-content-center align-items-center"
                     style={{position: 'absolute', height: '100vh', backgroundColor: 'transparent'}}>
                    <Lottie animationData={successAnimation} loop={false} style={{width: '100%', height: 'auto'}}/>
                </div>
            ) : (
                <div className="position-relative">
                    <div
                        className="background-overlay position-absolute top-0 start-0 w-100 h-100"
                        style={{
                            backgroundImage: `url(https://3emelieu.ch${courseData.image})`,
                            opacity: 0.2,
                            filter: 'blur(6px)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    ></div>

                    <div className="content position-relative">
                        <Modal.Header closeButton style={{color: '#fff'}}>
                            <div style={{
                                border: `2px solid ${courseData.Type === 'Certificate' ? '#2ce5ff' :
                                    courseData.Type === 'Diploma' ? '#FFC107' :
                                        courseData.Type === 'Bachelor' ? '#ad21f3' : 'transparent'}`,
                                backgroundColor: `${courseData.Type === 'Certificate' ? 'rgba(44,229,255,0.5)' :
                                    courseData.Type === 'Diploma' ? 'rgba(255,193,7,0.5)' :
                                        courseData.Type === 'Bachelor' ? 'rgba(173,33,243,0.5)' : 'transparent'}`,
                                padding: 4,
                                borderRadius: 6
                            }}>{courseData.Type === 'Certificate' ? 'Certificat' :
                                courseData.Type === 'Diploma' ? 'Diplôme' :
                                    courseData.Type === 'Bachelor' ? 'Bachelor' : courseData.Type}</div>
                            <Modal.Title>{courseData.Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{color: '#ddd'}}>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <div className="mb-4">
                                <h5><Question size={32} weight="thin"
                                              style={{color: '#5257ff', marginRight: 10}}/> Description:
                                </h5>
                                <p style={{
                                    color: '#fff',
                                    fontSize: '1.3em',
                                    padding: 14
                                }}>{courseData.SmallDescription}</p>
                            </div>
                            <div className="mb-4">
                                <h5><Calendar size={32} weight="thin"
                                              style={{color: '#5257ff', marginRight: 10}}/> Agenda:
                                </h5>
                                {agendaElement}
                            </div>
                            {/*
                            <div className="mb-4"  style={{border: '2px solid rgba(255,255,255,0.1)', padding: 10}}>
                                <h5><Cube size={32} weight="thin" style={{color: '#5257ff', marginRight: 10}}/> Axes:
                                </h5>
                                {axesElements}
                            </div>
                            */}
                            {toolsAdmit}
                            {courseData.Subjects && (
                                <div className="mb-4">
                                    <h5><ListChecks size={32} weight="thin"
                                                    style={{color: '#5257ff', marginRight: 10}}/> Matières:</h5>
                                    <ul className="list-group">
                                        {subjectsElements}
                                    </ul>
                                </div>
                            )}
                            {courseData.Objective && (
                                <div className="mb-4">
                                    <h5><Target size={32} weight="thin"
                                                style={{color: '#5257ff', marginRight: 10}}/> Objectifs:
                                    </h5>
                                    <ul className="list-group">
                                        {objectiveElements}
                                    </ul>
                                </div>
                            )}
                            {courseData.Tools && (
                                <div className="mb-4">
                                    <h5><Wrench size={32} weight="thin"
                                                style={{color: '#5257ff', marginRight: 10}}/> Logiciels
                                        & Outils:</h5>
                                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                        {toolsElements}
                                    </div>
                                </div>
                            )}
                            {courseData.Career && (
                                <div className="mb-4">
                                    <h5><Briefcase size={32} weight="thin"
                                                   style={{color: '#5257ff', marginRight: 10}}/> Carrière &
                                        Perspectives:
                                    </h5>
                                    <ul className="list-group">
                                        {careerElements}
                                    </ul>
                                </div>
                            )}
                            {courseData.Matos && (
                                <div className="bg-3emelieu-2 p-4 rounded">
                                    <h5 className="h4-slug d-flex align-items-center">
                                        <Laptop size={32} weight="thin"
                                                style={{color: '#5257ff', marginRight: '10px'}}/> Inclus
                                        avec votre formation
                                    </h5>
                                    <div className="matos">
                                        <div>
                                            {matosData.image && (
                                                <img src={matosData.image}
                                                     alt={matosData.h1}
                                                     style={{maxWidth: '320px', borderRadius: '8px', margin: 12}}/>
                                            )}
                                        </div>
                                        <div>
                                            <h1 className="h1">{matosData.h1}</h1>
                                            <hr style={{margin: 4}}/>
                                            <h3 className="h5"
                                                dangerouslySetInnerHTML={{__html: matosData.h2}}></h3>
                                            <hr style={{margin: 4}}/>
                                            <div
                                                dangerouslySetInnerHTML={{__html: matosData.description}}></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer style={{borderTop: '1px solid #fff'}}>
                            <Button
                                variant="primary"
                                onClick={handleConfirmCrowdfunding}
                                disabled={isCrowdfundingActive}
                            >
                                {isCrowdfundingActive ? `Un crowdfunding est déjà actif, impossible d'en activer un autre.` : 'Activer le crowdfunding pour financer ce cours'}
                                {/* {isCrowdfundingActive ? `Crowdfunding actif, ${remainingDays} jours restants` : 'Activer le crowdfunding pour financer ce cours'} */}
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </div>
                </div>
            )}

            <Modal show={showConfirmation} onHide={handleConfirmClose} centered>
                <div className="modal-header text-white">
                    <h5 className="modal-title" id="exampleModalLabel">
                        <Lightbulb size={32}/> Confirmation
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body bg-dark text-light">
                    <p style={{fontSize: '1.2em', textAlign: 'center'}}>
                        Vous êtes sur le point d'activer le crowdfunding pour le cours
                        " <strong>{courseData.Title}</strong> ".
                    </p>
                    <hr/>
                    <p style={{color: '#fff'}}>
                        L'activation du financement participatif génère une page personnelle permettant à l'étudiant de
                        financer son propre cursus.<br/>
                        <strong>⚠️ Seule une campagne de financement participatif peut être activée à la fois.</strong>
                    </p>
                    <hr/>
                </div>
                <div className="modal-footer bg-dark border-top-0">
                    <Button variant="secondary" onClick={handleConfirmClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleConfirmActivate}>
                        <CheckCircle size={32}/> Confirmer
                    </Button>
                </div>
            </Modal>
        </Modal>
    );
};

export default CourseDetailModal;
