// src/pages/index.tsx

import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import VerifyEmail from "./verify-email";
import AuthenticatedRoute from "../components/auth";
import { NotFound } from "./404";
import { User, Dashboard, Cours, Crowdfunding, TalkHive } from "../components";
import SuccessPage from '../components/success';
import ErrorPage from '../components/error';
import Registered from '../pages/registered';
import PostList from "../components/upvote";

const Pages: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/registered" element={<Registered />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/cancel" element={<ErrorPage />} />
                <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />} />
                <Route path="/cours" element={<AuthenticatedRoute element={<Cours />} />} />
                <Route path="/upvote" element={<AuthenticatedRoute element={<PostList />} />} />
                {/* <Route path="/talkhive" element={<AuthenticatedRoute element={<TalkHive />} />} /> */}
                <Route path="/user" element={<AuthenticatedRoute element={<User />} />} />
                <Route path="/user/crowdfunding/:id" element={<Crowdfunding />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Pages;
