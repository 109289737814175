import React, {useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useAppDispatch} from "../redux/store";
import {tokenAPICALL} from "../services/authService";
import {setJWTToken, setError} from "../redux/slices/auth";
import {Alert, Button, Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {Constant} from "../template/Constant";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);

    useEffect(() => {
        const now = Date.now();
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');

        if (token && expire && parseInt(expire) > now) {
            navigate('/dashboard', {replace: true});
        }
    }, [navigate]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false
        },
        onSubmit: async (values) => {
            try {
                const response = await tokenAPICALL(values.username, values.password, values.rememberMe);

                if (response) {
                    const expiryDate = Date.now() + (10 * 60 * 60 * 1000); // 10 hours in milliseconds

                    dispatch(setJWTToken({
                        jwtToken: response.jwtToken, // Use the correct property from the response
                        expiryDate: expiryDate.toString(), // Ensure expiryDate is stored as string
                        userData: response.userData,
                        errorMessage: null
                    }));

                    // Store in localStorage
                    console.log('Storing jwtToken:', response.jwtToken); // Log token
                    localStorage.setItem('jwtToken', response.jwtToken);
                    localStorage.setItem('expiryDate', expiryDate.toString());
                    localStorage.setItem('userData', JSON.stringify(response.userData));

                    // Redirect to dashboard
                    setTimeout(() => {
                        console.log('Redirecting to /dashboard');
                        navigate('/dashboard', {replace: true});
                    }, 500);
                } else {
                    dispatch(setError("Identifiants invalides"));
                }
            } catch (error) {
                console.error('Error during login:', error);
                dispatch(setError("Une erreur s'est produite lors de la connexion."));
            }
        },
        validationSchema: yup.object({
            username: yup.string().trim().required('Le nom d\'utilisateur est requis'),
            password: yup.string().trim().required('Le mot de passe est requis'),
        }),
    });

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
        return () => {
            document.body.classList.toggle('bg-gradient-primary', false);
        };
    }, []);

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 bg-login-image" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '3rem'
                                }}>
                                    <img src="./3emelieu_logo_and_txt_Blanc.png" alt="logo 3èmelieu SA"
                                         style={{maxWidth: '100%', margin: 0, display: 'block'}}/>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Email</label>
                                                <Form.Control
                                                    type="text"
                                                    name="username"
                                                    className="form-control-user"
                                                    value={formik.values.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.username && !!formik.errors.username}
                                                    isValid={!!formik.touched.username && !formik.errors.username}
                                                />
                                                {formik.errors.username && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <label className="form-control-label">Mot de passe</label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    className="form-control-user"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                    isValid={!!formik.touched.password && !formik.errors.password}
                                                />
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            {/*
                                            <Form.Group>
                                                <div style={{ margin: 10, marginTop: 15 }}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Se souvenir de moi"
                                                        name="rememberMe"
                                                        checked={formik.values.rememberMe}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </Form.Group>
                                            */}
                                            <div style={{marginTop: 32}}>
                                                <Button type="submit" className="btn-user btn-block" variant="primary">
                                                    Connexion
                                                </Button>
                                            </div>
                                        </Form>
                                        <hr style={{marginTop: 32, borderColor: 'rgba(255,255,255,0.1)'}}/>
                                        {rData.errorMessage ? (
                                            <Alert variant={Constant.defaultAlertVarient}
                                                   className="alert-dismissible fade">
                                                {rData.errorMessage}
                                                <Button type="button" className="close" data-dismiss="alert"
                                                        aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        ) : null}
                                        {/*
                                        <Link to="/forgot-password">
                                            <div className="text-center" style={{ marginTop: 0, marginBottom: 15 }}>
                                                Mot de passe oublié ?
                                            </div>
                                        </Link>
                                        */}
                                        <Link to="/register" style={{textDecoration: 'none'}}>
                                            <div className="text-center btn-info">
                                                Créer un compte !
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="copyright text-center my-auto small">
                                <span>Copyright &copy; 3èmelieu SA 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
