import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Spinner, Container } from 'react-bootstrap';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';
const VerifyEmail: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (!token) {
            setError('Token is missing');
            setLoading(false);
            return;
        }

        const verifyEmail = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/v1/verify-email?token=${token}`);
                setSuccess(response.data.message);
                setTimeout(() => navigate('/login'), 3000);
            } catch (error) {
                setError('Verification failed. The token might be invalid or expired.');
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [location.search, navigate]);

    return (
        <Container className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="text-center">
                {loading && <Spinner animation="border" />}
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
            </div>
        </Container>
    );
};

export default VerifyEmail;
