const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';
export const getCourses = async (params) => {
    const queryString = new URLSearchParams({
        page: params.page,
        pageSize: params.pageSize,
        searchKey: params.searchKey,
        filters: JSON.stringify(params.filters)
    }).toString();

    console.log('queryString', queryString.toString());
    try {
        const response = await fetch(`${API_BASE_URL}/courses?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API call response:', data);
        return data;
    } catch (error) {
        console.error('Error during API call:', error);
        throw error;
    }
};
