import React from "react";
import { useNavigate } from "react-router";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { activateMenu, activateTopMenu, sidebarToggle } from "./../redux/actions";
import { RootState } from "./../redux/reducers";
import { useAppDispatch } from "./../redux/store";
import MobileMenu from "./MobileMenu";

const Topbar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isTopActiveMenu = useSelector((state: RootState) => state.template.topActiveMenu);
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;

    const handleMenuClick = (path: string) => {
        dispatch(activateMenu(path));
        dispatch(activateTopMenu("")); // Close the dropdown
        navigate(path, { replace: true });
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch(activateTopMenu("")); // Close the dropdown
        navigate('/', { replace: true });

        setTimeout(() => {
            navigate('/', { replace: true });
        }, 3000); // 3 seconds timeout
    };

    return (
        <>
            <Nav className="navbar navbar-expand navbar-dark topbar mb-4 static-top shadow">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow d-none d-md-block">
                        <a
                            className="nav-link dropdown-toggle"
                            id="userDropdown"
                            role="button"
                            onClick={() => dispatch(activateTopMenu(isTopActiveMenu === "Profile" ? "" : "Profile"))}
                            aria-haspopup="true"
                            aria-expanded={isTopActiveMenu === "Profile"}
                        >
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                {userProfile ? `${userProfile.first_name} ${userProfile.last_name}` : 'NA'}
                            </span>
                            <img
                                className="img-profile rounded-circle"
                                src={userProfile?.profile_picture || "void.png"}
                                alt="User profile"
                            />
                        </a>
                        <div
                            className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${isTopActiveMenu === "Profile" ? "show" : ""}`}
                            aria-labelledby="userDropdown"
                        >
                            <Button
                                variant="link"
                                className="dropdown-item"
                                onClick={() => handleMenuClick('/user')}
                            >
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                {userProfile ? `${userProfile.first_name} ${userProfile.last_name}` : 'NA'}
                            </Button>
                            <div className="dropdown-divider"></div>
                            <Button
                                variant="link"
                                className="dropdown-item"
                                onClick={handleLogout}
                            >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Déconnexion
                            </Button>
                        </div>
                    </li>
                </ul>
                <Button
                    id="sidebarToggleTop"
                    className="btn btn-link text-dark d-md-none rounded-circle ml-auto"
                    onClick={() => dispatch(sidebarToggle())}
                >
                    <i className="fa fa-bars"></i>
                </Button>
            </Nav>
            <MobileMenu />
        </>
    );
};

export default Topbar;
