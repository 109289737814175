import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, Link } from "react-router-dom";
import { registerUser, checkEmailExists } from "services/authService";
import { Alert, Button, Form, Card, Spinner } from "react-bootstrap";
import Layout from "template";

const Register: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: yup.object({
            firstName: yup.string().required('Le prénom est requis'),
            lastName: yup.string().required('Le nom est requis'),
            email: yup.string().email('Adresse e-mail invalide').required('L\'adresse e-mail est requise'),
            password: yup.string().min(6, 'Le mot de passe doit contenir au moins 6 caractères').required('Le mot de passe est requis'),
            repeatPassword: yup.string()
                .oneOf([yup.ref('password'), null], 'Les mots de passe doivent correspondre')
                .required('Veuillez répéter le mot de passe'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setError(null);
            setSuccess(null);
            try {
                const emailExists = await checkEmailExists(values.email);
                if (emailExists) {
                    setError("L'adresse e-mail est déjà utilisée.");
                    setLoading(false);
                    return;
                }

                await registerUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password
                });

                setSuccess("Inscription réussie ! Veuillez vérifier votre e-mail pour activer votre compte. Si vous ne recevez pas de message dans les 5 minutes, pensez à vérifier votre dossier de courriers indésirables (SPAMS).");
            } catch (error) {
                setError("L'inscription a échoué. Veuillez réessayer.");
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                    <img
                        src="./3emelieu_logo_and_txt_Blanc.png"
                        alt="logo 3èmelieu SA"
                        className="img-fluid"
                        style={{ maxWidth: '80%', margin: '6px auto' }}
                    />
                </div>

                <div className="col-12 col-md-8 col-lg-6 mb-4">
                    <Card className="shadow-lg">
                        <Card.Body>
                            <div className="text-center mb-4">
                                <h1 className="h4 text-gray-900">Créer un compte</h1>
                            </div>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            {success ? (
                                <div className="text-center">
                                    <Button variant="primary" onClick={() => navigate("/")}>
                                        Aller à la connexion
                                    </Button>
                                </div>
                            ) : (
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.firstName && !!formik.errors.firstName}
                                            isValid={!!formik.touched.firstName && !formik.errors.firstName}
                                        />
                                        {formik.errors.firstName && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.firstName}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.lastName && !!formik.errors.lastName}
                                            isValid={!!formik.touched.lastName && !formik.errors.lastName}
                                        />
                                        {formik.errors.lastName && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.lastName}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Adresse e-mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.email && !!formik.errors.email}
                                            isValid={!!formik.touched.email && !formik.errors.email}
                                        />
                                        {formik.errors.email && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.email}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mot de passe</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.password && !!formik.errors.password}
                                            isValid={!!formik.touched.password && !formik.errors.password}
                                        />
                                        {formik.errors.password && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.password}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Répétez le mot de passe</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="repeatPassword"
                                            value={formik.values.repeatPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={!!formik.touched.repeatPassword && !!formik.errors.repeatPassword}
                                            isValid={!!formik.touched.repeatPassword && !formik.errors.repeatPassword}
                                        />
                                        {formik.errors.repeatPassword && (
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.repeatPassword}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <div className="mt-4">
                                        <Button type="submit" className="w-100 btn-lg" variant="primary" disabled={loading}>
                                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : "Créer un compte"}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                            <hr/>
                            <div className="text-center">
                                <Link className="small" to="/">Vous avez déjà un compte ? Connexion !</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Register;
