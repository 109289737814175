// src/components/SuccessPage.tsx

import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

const Registered: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/");
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="container">
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <Card className="shadow p-4 text-center">
                    <Card.Body>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img src="ok.png" alt="ok" style={{margin: 32, width: '128px', height: 'auto'}}/>
                        </div>
                        <h1 className="h4 text-gray-900 mb-4">Inscription réussie !</h1>
                        <p>Un e-mail de confirmation a été envoyé à votre adresse e-mail.</p>
                        <p>Vous serez redirigé vers la page de connexion dans quelques secondes...</p>
                        <Button variant="primary" onClick={() => navigate("/")}>
                            Aller à la page de connexion
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default Registered;
