// services/authService.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

export const tokenAPICALL = async (username: string, password: string, rememberMe: boolean) => {
    try {
        const response = await fetch(`${API_BASE_URL}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: username, password }),
        });

        const data = await response.json();
        console.log("API Response:", data);

        if (response.ok) {
            const expiryDuration = data.expiryDuration || 30 * 24 * 60 * 60 * 1000; // 30 jours par défaut
            return {
                jwtToken: data.jwtToken,
                expiryDuration: expiryDuration,
                userData: data.userData,
            };
        } else {
            console.error("API Error:", data.message || "Unknown error");
            return null;
        }
    } catch (error) {
        console.error("Error during token API call:", error);
        return null;
    }
};

export const registerUser = async (userData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                password: userData.password,
            })
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            console.error("Erreur d'inscription:", errorData.message || "Unknown error");
            throw new Error('Échec de l\'inscription de l\'utilisateur');
        }
    } catch (error) {
        console.error("Erreur d'inscription", error);
        throw error;
    }
};

export const checkEmailExists = async (email: string) => {
    try {
        const response = await fetch(`${API_BASE_URL}/users/check-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            throw new Error('Failed to check email');
        }

        const data = await response.json();
        return data.exists;
    } catch (error) {
        console.error('Error checking email existence:', error);
        throw error;
    }
};
