import React, { useState } from "react";
import Layout from "template";
import { Container, Row, Col } from "react-bootstrap";
import Post from "./Post";

const initialPosts = [
    { id: 1, title: "Réforme des transports publics", content: "Discours sur les récentes réformes des transports publics à Genève.", votes: 0 },
    { id: 2, title: "Budget cantonal 2024", content: "Analyse du nouveau budget cantonal et ses implications pour les citoyens.", votes: 0 },
    { id: 3, title: "Initiative populaire sur le logement", content: "Débat autour de l'initiative visant à réguler les loyers dans la ville.", votes: 0 },
    { id: 4, title: "Transition énergétique", content: "Discussion sur les mesures pour une Genève plus verte et durable.", votes: 0 },
];

const PostList: React.FC = () => {
    const [posts, setPosts] = useState(initialPosts);

    const handleVote = (id: number, change: number) => {
        setPosts(posts.map(post =>
            post.id === id ? { ...post, votes: post.votes + change } : post
        ));
    };

    return (
        <Layout>
            <Container fluid className="px-4 mb-4">
                <Row>
                    {posts.map(post => (
                        <Col key={post.id} md={6} lg={4} className="mb-4">
                            <Post
                                id={post.id}
                                title={post.title}
                                content={post.content}
                                votes={post.votes}
                                onVote={handleVote}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Layout>
    );
};

export default PostList;
