// authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    jwtToken: string | null;
    expiryDate: string | null;
    userData: any;
    errorMessage: string | null;
}

const initialState: AuthState = {
    jwtToken: null,
    expiryDate: null,
    userData: null,
    errorMessage: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setJWTToken(state, action: PayloadAction<{
            jwtToken: string;
            expiryDate: string;
            userData: any;
            errorMessage: string | null;
        }>) {
            state.jwtToken = action.payload.jwtToken;
            state.expiryDate = action.payload.expiryDate;
            state.userData = action.payload.userData;
            state.errorMessage = action.payload.errorMessage;
        },
        setError(state, action: PayloadAction<string>) {
            state.errorMessage = action.payload;
        }
    }
});

export const { setJWTToken, setError } = authSlice.actions;
export default authSlice.reducer;
