import React, { useEffect, useState } from "react";
import { Button, Form, Spinner, Alert, Card } from "react-bootstrap";
import { updateUser } from "services/usersService";
import Layout from "template";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setCurrentUser } from "redux/slices/users";
import { setJWTToken } from "redux/slices/auth";
import { Navigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Roadmap from "./Roadmap";

const countries = ["France", "Belgique", "Suisse", "Canada", "Luxembourg"];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

export const User: React.FC = () => {
    const dispatch = useDispatch();
    const authToken = useSelector((state: RootState) => state.authToken);
    const currentUser = authToken.userData;
    const [id, setId] = useState(currentUser?.id || "");
    const [firstName, setFirstName] = useState(currentUser?.first_name || "");
    const [lastName, setLastName] = useState(currentUser?.last_name || "");
    const [email, setEmail] = useState(currentUser?.email || "");
    const [profilePicture, setProfilePicture] = useState(currentUser?.profile_picture || "");
    const [address, setAddress] = useState(currentUser?.address || "");
    const [postalCode, setPostalCode] = useState(currentUser?.postal_code || "");
    const [country, setCountry] = useState(currentUser?.country || "");
    const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone_number || "");
    const [birthDate, setBirthDate] = useState(currentUser?.birth_date || "");
    const [roadmap, setRoadmap] = useState(Array.isArray(currentUser?.roadmap) ? currentUser.roadmap : []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const handleProfilePictureUpload = async (file) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', file);
            const uploadResponse = await fetch(`${API_BASE_URL}/api/v1/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken.jwtToken}`
                },
                body: formData
            });
            const uploadData = await uploadResponse.json();
            setProfilePicture(uploadData.document);

            // Automatically save the new profile picture URL to the user data
            const updatedUserData = {
                id,
                first_name: firstName,
                last_name: lastName,
                email,
                profile_picture: uploadData.document,
                address,
                postal_code: postalCode,
                country,
                phone_number: phoneNumber,
                birth_date: birthDate,
                roadmap
            };

            const response = await updateUser(updatedUserData, id);
            const updatedUser = response[0]; // Assuming the response is an array with one user object

            // Fetch existing user data from local storage
            const existingUserData = localStorage.getItem('userData');
            let mergedUserData;

            if (existingUserData) {
                // Merge new data with existing data
                mergedUserData = { ...JSON.parse(existingUserData), ...updatedUser };
            } else {
                mergedUserData = updatedUser;
            }

            // Update local storage with merged data
            localStorage.setItem('userData', JSON.stringify(mergedUserData));

            // Update Redux store with new data
            dispatch(setCurrentUser(mergedUserData));

            // Log the updated user data from local storage
            console.log("Updated user data in local storage:", mergedUserData);

            // Log the current state of Redux store
            console.log("Updated user data in Redux store:", mergedUserData);

            setSuccess("Photo de profil mise à jour avec succès.");
            setError(null);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la photo de profil:", error);
            setError("Échec de la mise à jour de la photo de profil.");
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            handleProfilePictureUpload(file);
        }
    });

    useEffect(() => {
        const now = Date.now();
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');
        const storedUserData = localStorage.getItem('userData');

        if (token && expire && parseInt(expire) > now && storedUserData) {
            const userData = JSON.parse(storedUserData);
            userData.roadmap = Array.isArray(userData.roadmap) ? userData.roadmap : [];
            if (!authToken.jwtToken) {
                dispatch(setJWTToken({
                    jwtToken: token,
                    expiryDate: expire,
                    userData: userData,
                    errorMessage: ""
                }));
                dispatch(setCurrentUser(userData));
            }
        } else {
            setIsAuthenticated(false);
        }
    }, [dispatch, authToken.jwtToken]);

    const handleSaveUser = async () => {
        setLoading(true);
        const updatedUserData = {
            id,
            first_name: firstName,
            last_name: lastName,
            email,
            profile_picture: profilePicture,
            address,
            postal_code: postalCode,
            country,
            phone_number: phoneNumber,
            birth_date: birthDate,
            roadmap
        };

        console.log("User data before update:", updatedUserData);

        try {
            const response = await updateUser(updatedUserData, id);
            const updatedUser = response[0]; // Assuming the response is an array with one user object
            console.log("User updated successfully:", updatedUser);

            // Fetch existing user data from local storage
            const existingUserData = localStorage.getItem('userData');
            let mergedUserData;

            if (existingUserData) {
                // Merge new data with existing data
                mergedUserData = { ...JSON.parse(existingUserData), ...updatedUser };
            } else {
                mergedUserData = updatedUser;
            }

            // Update local storage with merged data
            localStorage.setItem('userData', JSON.stringify(mergedUserData));

            // Update Redux store with new data
            dispatch(setCurrentUser(mergedUserData));

            // Log the updated user data from local storage
            console.log("Updated user data in local storage:", mergedUserData);

            // Log the current state of Redux store
            console.log("Updated user data in Redux store:", mergedUserData);

            setSuccess("Détails de l'utilisateur mis à jour avec succès.");
            setError(null);
        } catch (error) {
            console.error("Erreur lors de la mise à jour des détails de l'utilisateur:", error);
            setError("Échec de la mise à jour des détails de l'utilisateur.");
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        const userData = JSON.parse(storedUserData || '{}');
        userData.roadmap = Array.isArray(userData.roadmap) ? userData.roadmap : [];
        setRoadmap(userData.roadmap);
        console.log("Current user data in local storage:", userData);
    }, [currentUser]);

    useEffect(() => {
        console.log("Current user data in Redux store:", currentUser);
    }, [currentUser]);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <Layout>
            <div className="container">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Détails de l'utilisateur</h1>
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Card className="shadow mb-4">
                        <Card.Header className="py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Informations de l'utilisateur</h6>
                        </Card.Header>
                        <Card.Body>
                            {success && <Alert variant="success">{success}</Alert>}
                            {error && <Alert variant="danger">{error}</Alert>}
                            <div {...getRootProps({ className: 'dropzone' })}
                                 style={{ textAlign: 'center', marginBottom: '1rem' }}>
                                <input {...getInputProps()} />
                                {profilePicture ? (
                                    <img src={typeof profilePicture === 'string' ? profilePicture : URL.createObjectURL(profilePicture)} alt="Profile" style={{
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }} />
                                ) : (
                                    <img src='./void.png' alt="Profile" style={{
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }}/>
                                )}
                            </div>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Code postal</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="postal_code"
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        <option value="">Sélectionnez un pays</option>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Numéro de téléphone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone_number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Date de naissance</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="birth_date"
                                        value={birthDate}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                    />
                                </Form.Group>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                    <Button variant="primary" onClick={handleSaveUser} disabled={loading}>
                                        {loading ? <Spinner as="span" animation="border" size="sm" role="status"
                                                            aria-hidden="true" /> : "Enregistrer les modifications"}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                )}
                {/*
                <Card>
                    <div style={{ marginBottom: 128 }}>
                        <Card.Body>
                            <Roadmap roadmap={roadmap} setRoadmap={setRoadmap} />
                        </Card.Body>
                    </div>
                </Card>
                */}
            </div>
        </Layout>
    );
};
