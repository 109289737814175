import React from "react";
import { Button } from "react-bootstrap";

interface ChannelsProps {
    channels: string[];
    onChannelSelect: (channel: string) => void;
    selectedChannel: string;
}

const Channels: React.FC<ChannelsProps> = ({ channels, onChannelSelect, selectedChannel }) => {
    return (
        <div className="talk-sidebar">
            <h4>Channels</h4>
            <ul className="talk-channel-list">
                {channels.map(channel => (
                    <li
                        key={channel}
                        className={channel === selectedChannel ? 'active' : ''}
                        onClick={() => onChannelSelect(channel)}
                    >
                        #{channel}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Channels;
