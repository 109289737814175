import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ErrorPage: React.FC = () => {
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title className="text-danger">Paiement échoué</Card.Title>
                            <Card.Text>
                                Une erreur s'est produite lors du traitement de votre paiement. Veuillez réessayer.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorPage;
