import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsers {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    profile_picture: string;
    reserved_funds: number;
    usable_funds: number;
    wallet_history: string;
    targetAmount: number;
    daysLeft: number;
    backers: number;
    cursus: string;
}

interface IUsersData {
    currentUser?: IUsers;
    list?: Array<IUsers>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IUsersData = {
    currentUser: undefined,
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<IUsers>) => {
            state.currentUser = action.payload;
        },
        clearCurrentUser: (state) => {
            state.currentUser = undefined;
        },
        setUsersList: (state, action: PayloadAction<IUsersData>) => {
            state.list = action.payload.list;
            state.pageNo = action.payload.pageNo;
            state.pageSize = action.payload.pageSize;
            state.totalCount = action.payload.totalCount;
        },
        resetUsersToInit: (state) => {
            state.currentUser = undefined;
            state.list = [];
            state.pageNo = 1;
            state.pageSize = 20;
            state.searchKey = '';
            state.totalCount = 0;
            state.message = '';
        },
        setUsersMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
    },
});

export const { setCurrentUser, clearCurrentUser, setUsersList, resetUsersToInit, setUsersMessage } = userSlice.actions;
export default userSlice.reducer;
